import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
   {
      path: '/:catchAll(.*)',
      name: 'home',
      component: () => import('../dashboard/Page.vue')
   }
]

const router = createRouter({
   history: createWebHashHistory(),
   routes
})
export default router