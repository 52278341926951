<template>
  <div class="wrapper">
    <ml-preloader></ml-preloader>
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import $ from 'jquery'

import Preloader from "./dashboard/Preloader.vue"
// import widgets list
import widgetComponents from "./widgets/list.js"
import components from "./components/list.js"

export default {
  name: "App",
  components: {
    'ml-preloader': Preloader
  },
  methods: {
    /**
     * 取得控制整個前端的 Metadata
     */
    loadMetadata: function () {
      var cp_api_url = $('meta[name="cp_api_url"]').attr('content');
      var base_api_url = $('meta[name="base_api_url"]').attr('content');

      // 從選單API取得整個系統的 Metadata
      let information = this.$microlink.api(cp_api_url + '/information/get');
      let menu = this.$microlink.api(cp_api_url + '/menu/get');
      let pages = this.$microlink.api(cp_api_url + '/page/list/path');
      let sides = this.$microlink.api(cp_api_url + '/side/list');
      let widgets = this.$microlink.api(cp_api_url + '/widget/list');

      return {
        'information': information,
        'menu': menu,
        'pages': pages.data,
        'sides': sides,
        'widgets': widgets.data,
        'base_api_url': base_api_url
      };
    },
    /**
     * <<遞迴函式>>
     * 取得整個選單中的所有 router 路徑
     * 並動態加入 router 設定中
     * @param {*} items 
     */
    loadRouters: function (items) {
      
      items.forEach((item) => {
         // 如有子項目，則遞迴繼續呼叫處理
        if (item.subitems && item.subitems.length > 0) {
          this.loadRouters(item.subitems);
        }
        // 選單項目有指定路徑 或 路由的名稱不重複的狀況下，可新增路由
        else if (item.path != '' && !this.$router.hasRoute(item.id)) {

          // 確保路徑是絕對路徑
          const absolutePath = item.path.startsWith('/') ? item.path : `/${item.path}`;

          // 新增路由
          this.$router.addRoute({ name: item.id, path: absolutePath, component: () => import('@/dashboard/Page.vue') });
        }
      });
    }
  },
  mounted() {

    // 確保路徑是絕對路徑
    const absolutePath = this.$route.path.startsWith('/') ? this.$route.path : `/${this.$route.path}`;

    this.$router.replace({ path: absolutePath });

    /*
    // 禁用浏览器的前进和后退按钮
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      // 禁止上一頁，但是允許登入頁面
      if (window.history.state.current == '/login' ||
        window.history.state.current == '/') {
        return;
      }

      $this.$microlink.alert('禁止上一頁', '請使用選單進行操作', 'warning');
      window.history.go(1);
    };

    // 禁用浏览器的历史记录更改
    window.history.replaceState(window.history.state, '', window.location.href);
    */
  },
  beforeUnmount() {
    // 恢复浏览器的默认行为
    window.onpopstate = null;
    window.history.replaceState = null;
  },
  created() {
    // 取得控制整個前端的 Metadata
    let metadata = this.loadMetadata();
    this.$microlink.information = metadata.information;
    this.$microlink.menu = metadata.menu.menu;
    this.$microlink.pages = metadata.pages;
    this.$microlink.widgets = metadata.widgets;
    this.$microlink.sides = metadata.sides;
    this.$microlink.widgetComponents = widgetComponents;
    this.$microlink.components = components;
    this.$microlink.base_api_url = metadata.base_api_url;


    // 登入頁面
    this.$router.addRoute({ name: 'login', path: '/login', component: () => import('@/dashboard/Login.vue') });

    // 根據選單資訊，重新導入路由
    this.loadRouters(this.$microlink.menu);

    let url_path = (location.href).split('#')[1];

    if (this.$microlink.pages[url_path] == undefined && url_path != '/login') {
      url_path = '/';
    }


    // 首頁預設為選單中的第一個頁面
    if (url_path == '/') {
      if (this.$microlink.pages[url_path] == undefined) {
        url_path = Object.keys(this.$microlink.pages)[0];
      }
    }

    this.$route.path = url_path;
  }

};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
</style>
