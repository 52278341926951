import { defineAsyncComponent, markRaw, reactive } from 'vue';
const widgetComponents = reactive({
    'table': markRaw(defineAsyncComponent(() => import('./table/Table.vue'))),
    'grid': markRaw(defineAsyncComponent(() => import('./grid/Grid.vue'))),
    'list': markRaw(defineAsyncComponent(() => import('./list/List.vue'))),
    'sort': markRaw(defineAsyncComponent(() => import('./sort/Sort.vue'))),
    'form': markRaw(defineAsyncComponent(() => import('./form/Form.vue'))),
    'functionlist': markRaw(defineAsyncComponent(() => import('./functionlist/FunctionList.vue'))),
    'message': markRaw(defineAsyncComponent(() => import('./message/Message.vue'))),
    'userinfo': markRaw(defineAsyncComponent(() => import('./userinfo/Userinfo.vue')))
})
export default widgetComponents