import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
window.$ = window.jQuery = require("jquery");

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGooglePlus } from '@fortawesome/free-brands-svg-icons';


//import adminlte scripts
import "../node_modules/admin-lte/dist/js/adminlte.min.js"
import "../node_modules/admin-lte/plugins/select2/js/select2.full.min.js"
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"
import "../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"
//import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.js"
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js"
//mport "../node_modules/admin-lte/plugins/moment/moment.min.js"
import "../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js"
//import "../node_modules/admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js"
//import "../node_modules/admin-lte/plugins/jqvmap/jquery.vmap.min.js"
import "../node_modules/admin-lte/plugins/sparklines/sparkline.js"
//import "../node_modules/admin-lte/plugins/chart.js/Chart.min.js"
import "../node_modules/admin-lte/plugins/jquery/jquery.min.js"
import "../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js"
import "../node_modules/admin-lte/plugins/inputmask/jquery.inputmask.min.js"
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js"
import "../node_modules/admin-lte/plugins/bs-stepper/js/bs-stepper.min.js"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.js"
import "../node_modules/admin-lte/plugins/ion-rangeslider/js/ion.rangeSlider.js"


//import adminlte styles
import '../node_modules/admin-lte/dist/css/adminlte.min.css'
import '../node_modules/jquery-ui/dist/themes/blitzer/jquery-ui.css'
import "../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css"
import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css"
import "../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css"
import "../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"
import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"
import "../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css"
import "../node_modules/admin-lte/plugins/bs-stepper/css/bs-stepper.min.css"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css"
import "../node_modules/admin-lte/plugins/ion-rangeslider/css/ion.rangeSlider.min.css"

// sweetalert2
import Swal from "../node_modules/sweetalert2/dist/sweetalert2.all.min.js"

library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);
dom.watch()

const app = createApp(App)
app.use(router)
app.use(VueAxios, axios)
app.component("font-awesome-icon", FontAwesomeIcon)

// Microlink 所有的全域變數
import $ from 'jquery'
class microlink {
    data() {
        return {
            menu: [],
            pages: [],
            widgets: [],
            sides: [],
            widgetComponents: [],
            components: [],
            base_api_url: '',
            userinfo: {}
        }
    }
}
microlink.api = function (api_url) {
    let data = [];
    $.ajax({
        type: 'GET',
        dataType: 'JSON',
        async: false,
        url: api_url,
        crossDomain: true,
        xhrFields: {
            withCredentials: true
        },
        success: function (response) {
            if (response.status == 'success') {
                data = response.data;
            }
            else if (response.status == 'no_logined') {
                if (!microlink.check_remember_login()) {
                    // 如果未登入，開啟指定的全版面 Login Bootstrap Modal
                    microlink.show_login_modal();
                }
                else {
                    location.reload();
                }
            }
            else {
                console.error(response.type + '：' + response.data)
            }
        },
        error: function (e) {
            console.error(api_url + ' - 呼叫錯誤!', e)
        }
    });
    return data;
}

microlink.api_get = function (api_url, async, callback) {
    async = (async == undefined) ? false : async;
    let data = [];
    $.ajax({
        type: 'GET',
        dataType: 'JSON',
        async: async,
        url: api_url,
        crossDomain: true,
        xhrFields: {
            withCredentials: true
        },
        success: function (response) {
            if (async) {
                callback(response);
            }
            else {
                if (response.status == 'success') {
                    data = response.data;
                }
                else if (response.status == 'no_logined') {
                    if (!microlink.check_remember_login()) {
                        // 如果未登入，開啟指定的全版面 Login Bootstrap Modal
                        microlink.show_login_modal();
                    }
                    else {
                        location.reload();
                    }
                }
                else {
                    console.error(response.type + '：' + response.data)
                }
            }
        },
        error: function (e) {
            console.error(api_url + ' - 呼叫錯誤!', e)
        }
    });
    return data;
}

microlink.api_post = function (api_url, parameter, async, callback) {
    async = (async == undefined) ? false : async;
    let data = [];
    $.ajax({
        type: 'POST',
        data: parameter,
        dataType: 'JSON',
        async: async,
        url: api_url,
        crossDomain: true,
        xhrFields: {
            withCredentials: true
        },
        success: function (response) {
            if (async) {
                callback(response);
            }
            else {
                if (response.status == 'success') {
                    if (response.data != undefined) {
                        data = response.data;
                    }
                    else {
                        data = response;
                    }

                }
                else if (response.status == 'no_logined') {
                    if (!microlink.check_remember_login()) {
                        // 如果未登入，開啟指定的全版面 Login Bootstrap Modal
                        microlink.show_login_modal();
                    }
                    else {
                        location.reload();
                    }
                }
                else {
                    console.error(response.type + '：' + response.data)
                }
            }
        },
        error: function (e) {
            console.error(api_url + ' - 呼叫錯誤!', e)
        }
    });
    return data;
}
microlink.alert = function (title, text, icon, confirmButtonText) {

    confirmButtonText = (confirmButtonText == null || confirmButtonText == undefined) ? '確定' : confirmButtonText;

    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirmButtonText
    });
}
microlink.confirm = function (title, text, feedback, confirmButtonText, cancelButtonText) {

    confirmButtonText = (confirmButtonText == null || confirmButtonText == undefined) ? '確定' : confirmButtonText;
    cancelButtonText = (cancelButtonText == null || cancelButtonText == undefined) ? '取消' : cancelButtonText;


    Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        //cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText
    }).then((result) => {
        if (feedback != undefined) {
            feedback(result);
        }
    });
}
microlink.show_login_modal = function () {
    // 如果未登入，開啟指定的全版面 Login Bootstrap Modal
    $('#modal-ml-login').modal('show').addClass('d-flex');
}

microlink.check_remember_login = function () {
    let remember_token = localStorage.getItem('remember');
    // 如果有記住我資訊，則解碼後再以 ::: 分割
    if (remember_token != undefined && remember_token != '') {
        let remember = this.base64_decode(remember_token).split(':::');
        let time = remember[2];

        // 如果記住我資訊的時間超過 30 天，則清除記住我資訊
        if (Date.now() - time > 1000 * 60 * 60 * 24 * microlink.information.remember_days) {
            localStorage.removeItem('remember');
        }
        else {
            let api_url = microlink.base_api_url + microlink.information.login_check;

            let response = microlink.api_post(api_url, {
                'remember_token': remember_token
            });

            return (response.status == 'success');
        }
    }

    return false;
}

/**
* Base 64 編碼
*/
microlink.base64_encode = function (str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode('0x' + p1);
    }));
}

/**
* Base 64 解碼
*/
microlink.base64_decode = function (str) {
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

/**
 * 取得資料池的資料
 * @param {*} data_pool_name 
 */
microlink.get_data_pool = function (data_pool_name) {
    // 取得 data_pool_name 的全域變數
    let data_pool = window[data_pool_name];
    return data_pool;
}

/**
 * 設定資料池的資料
 */
microlink.set_data_pool = function (data_pool_name, data) {
    // 設定 data_pool_name 的全域變數
    window[data_pool_name] = data;
}
app.config.globalProperties.$microlink = microlink;

app.mount('#app')
