import { defineAsyncComponent, markRaw, reactive } from 'vue';
const components = reactive({
    'text': { 'title': '基本 - 文字方塊', 'component': markRaw(defineAsyncComponent(() => import('./text/Text.vue'))) },
    'button-list': { 'title': '按鈕清單', 'component': markRaw(defineAsyncComponent(() => import('./button-list/Button-List.vue'))) },
    'textarea': { 'title': '基本 - 多行文字方塊', 'component': markRaw(defineAsyncComponent(() => import('./textarea/Textarea.vue'))) },
    'label': { 'title': '基本 - 標籤', 'component': markRaw(defineAsyncComponent(() => import('./label/Label.vue'))) },
    'date': { 'title': '基本 - 日期', 'component': markRaw(defineAsyncComponent(() => import('./date/Date.vue'))) },
    'number': { 'title': '基本 - 數字', 'component': markRaw(defineAsyncComponent(() => import('./number/Number.vue'))) },
    'radio': { 'title': '基本 - 單選方塊', 'component': markRaw(defineAsyncComponent(() => import('./radio/Radio.vue'))) },
    'checkbox': { 'title': '基本 - 複選方塊', 'component': markRaw(defineAsyncComponent(() => import('./checkbox/Checkbox.vue'))) },
    'select': { 'title': '基本 - 下拉單選', 'component': markRaw(defineAsyncComponent(() => import('./select/Select.vue'))) },
    'label-group': { 'title': '基本 - 群組標籤', 'component': markRaw(defineAsyncComponent(() => import('./label_group/Label_Group.vue'))) },
    'switch': { 'title': '開關閘', 'component': markRaw(defineAsyncComponent(() => import('./switch/Switch.vue'))) },
    'image': { 'title': '基本 - 圖片', 'component': markRaw(defineAsyncComponent(() => import('./image/Image.vue'))) },
    'voice': { 'title': '基本 - 音效', 'component': markRaw(defineAsyncComponent(() => import('./voice/Voice.vue'))) },
    'slider': { 'title': '滑軌', 'component': markRaw(defineAsyncComponent(() => import('./slider/Slider.vue'))) },
    'frame': { 'title': '群組表單', 'component': markRaw(defineAsyncComponent(() => import('./frame/Frame.vue'))) },
    'hyperplasia': { 'title': '增生表單', 'component': markRaw(defineAsyncComponent(() => import('./hyperplasia/Hyperplasia.vue'))) },
    'fields': { 'title': '態動多欄表格', 'component': markRaw(defineAsyncComponent(() => import('./fields/Fields.vue'))) },
    'menu': { 'title': '開發者 - 選單編輯器', 'component': markRaw(defineAsyncComponent(() => import('./menu/Menu.vue'))) },
    'multi-select': { 'title': '多選下拉選單', 'component': markRaw(defineAsyncComponent(() => import('./multi-select/Multi-Select.vue'))) },
    'widget-select': { 'title': '開發者 - 組件下拉選單', 'component': markRaw(defineAsyncComponent(() => import('./widget-select/Widget-Select.vue'))) },
    'component-select': { 'title': '開發者 - 元件下拉選單', 'component': markRaw(defineAsyncComponent(() => import('./component-select/Component-Select.vue'))) },
    'datatype-select': { 'title': '開發者 - 型態下拉選單', 'component': markRaw(defineAsyncComponent(() => import('./datatype-select/Datatype-Select.vue'))) },
    'colume-width': { 'title': '開發者 - 欄位寬度設定', 'component': markRaw(defineAsyncComponent(() => import('./colume-width/Colume-Width.vue'))) },
    'select-chain': { 'title': '下拉選單鍊', 'component': markRaw(defineAsyncComponent(() => import('./select-chain/Select-Chain.vue'))) },
    'matrix': { 'title': '矩陣', 'component': markRaw(defineAsyncComponent(() => import('./matrix/Matrix.vue'))) }
})
export default components